import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class2, _descriptor;
import EmberObject, { action } from "@ember/object";
import { service } from "@ember/service";
import BulkSelectTopicsDropdown from "discourse/components/bulk-select-topics-dropdown";
import rawRenderGlimmer from "discourse/lib/raw-render-glimmer";
import i18n from "discourse-common/helpers/i18n";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
let _class = (_class2 = class _class2 extends EmberObject {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
  }
  get selectedCount() {
    return this.bulkSelectHelper.selected.length;
  }
  afterBulkAction() {
    return this.router.refresh();
  }
  get html() {
    return rawRenderGlimmer(this, "div.bulk-select-topics-dropdown", setComponentTemplate(createTemplateFactory(
    /*
      
            <span class="bulk-select-topic-dropdown__count">
              {{i18n "topics.bulk.selected_count" count=@data.selectedCount}}
            </span>
            <BulkSelectTopicsDropdown
              @bulkSelectHelper={{@data.bulkSelectHelper}}
              @afterBulkActionComplete={{@data.afterBulkAction}}
            />
          
    */
    {
      "id": "Ti7Oodp+",
      "block": "[[[1,\"\\n        \"],[10,1],[14,0,\"bulk-select-topic-dropdown__count\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"topics.bulk.selected_count\"],[[\"count\"],[[30,1,[\"selectedCount\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[32,1],null,[[\"@bulkSelectHelper\",\"@afterBulkActionComplete\"],[[30,1,[\"bulkSelectHelper\"]],[30,1,[\"afterBulkAction\"]]]],null],[1,\"\\n      \"]],[\"@data\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/raw-views/topic-bulk-select-dropdown.js",
      "scope": () => [i18n, BulkSelectTopicsDropdown],
      "isStrictMode": true
    }), templateOnly()), {
      bulkSelectHelper: this.bulkSelectHelper,
      selectedCount: this.selectedCount,
      afterBulkAction: this.afterBulkAction
    });
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class2.prototype, "afterBulkAction", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "afterBulkAction"), _class2.prototype)), _class2);
export { _class as default };