import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _DBreadcrumbsContainer;
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { modifier } from "ember-modifier";
import { eq } from "truth-helpers";
import concatClass from "discourse/helpers/concat-class";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let DBreadcrumbsContainer = (_class = (_DBreadcrumbsContainer = class DBreadcrumbsContainer extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "breadcrumbs", _descriptor, this);
    _defineProperty(this, "registerContainer", modifier(element1 => {
      const container1 = {
        element: element1
      };
      this.breadcrumbs.containers.add(container1);
      return () => this.breadcrumbs.containers.delete(container1);
    }));
  }
  get lastItemIndex() {
    return this.breadcrumbs.items.size - 1;
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <ul {{this.registerContainer}} class="d-breadcrumbs" ...attributes>
      {{#each this.breadcrumbs.items as |item index|}}
        {{#let item.templateForContainer as |Template|}}
          <Template
            @linkClass={{concatClass
              "d-breadcrumbs__link"
              @additionalLinkClasses
            }}
            aria-current={{if (eq index this.lastItemIndex) "page"}}
            class={{concatClass "d-breadcrumbs__item" @additionalItemClasses}}
          />
        {{/let}}
      {{/each}}
    </ul>
  
*/
{
  "id": "wkZlLMUk",
  "block": "[[[1,\"\\n    \"],[11,\"ul\"],[24,0,\"d-breadcrumbs\"],[17,1],[4,[30,0,[\"registerContainer\"]],null,null],[12],[1,\"\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"breadcrumbs\",\"items\"]]],null]],null],null,[[[44,[[30,2,[\"templateForContainer\"]]],[[[1,\"          \"],[8,[30,4],[[16,\"aria-current\",[52,[28,[32,0],[[30,3],[30,0,[\"lastItemIndex\"]]],null],\"page\"]],[16,0,[28,[32,1],[\"d-breadcrumbs__item\",[30,5]],null]]],[[\"@linkClass\"],[[28,[32,1],[\"d-breadcrumbs__link\",[30,6]],null]]],null],[1,\"\\n\"]],[4]]]],[2,3]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"&attrs\",\"item\",\"index\",\"Template\",\"@additionalItemClasses\",\"@additionalLinkClasses\"],false,[\"each\",\"-track-array\",\"let\",\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/d-breadcrumbs-container.js",
  "scope": () => [eq, concatClass],
  "isStrictMode": true
}), _DBreadcrumbsContainer), _DBreadcrumbsContainer), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "breadcrumbs", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { DBreadcrumbsContainer as default };