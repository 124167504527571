import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2;
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
let DBreadcrumbsItem = (_class = class DBreadcrumbsItem extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "breadcrumbs", _descriptor, this);
    _initializerDefineProperty(this, "router", _descriptor2, this);
    this.breadcrumbs.items.add(this);
  }
  willDestroy() {
    super.willDestroy(...arguments);
    this.breadcrumbs.items.delete(this);
  }
  get url() {
    if (this.args.model) {
      return this.router.urlFor(this.args.route, this.args.model);
    } else {
      return this.router.urlFor(this.args.route);
    }
  }
  get templateForContainer() {
    // Those are evaluated in a different context than the `@linkClass`
    const {
      label
    } = this.args;
    const url = this.url;
    return setComponentTemplate(createTemplateFactory(
    /*
      
          <li ...attributes>
            <a href={{url}} class={{@linkClass}}>
              {{label}}
            </a>
          </li>
        
    */
    {
      "id": "p9PhtpY/",
      "block": "[[[1,\"\\n      \"],[11,\"li\"],[17,1],[12],[1,\"\\n        \"],[10,3],[15,6,[32,0]],[15,0,[30,2]],[12],[1,\"\\n          \"],[1,[32,1]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[\"&attrs\",\"@linkClass\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/d-breadcrumbs-item.js",
      "scope": () => [url, label],
      "isStrictMode": true
    }), templateOnly());
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "breadcrumbs", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { DBreadcrumbsItem as default };